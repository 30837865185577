import { createAsyncThunk } from '@reduxjs/toolkit'
import toCamel from 'lodash-humps'
import {
  createFetchAsyncThunk,
  csrfToken,
  toSnake,
  throwErrorForErrorResponse,
  throwTextErrorForErrorResponse,
  errorHandler
} from '../common'
import { closeModal } from './subLayoutSlice'

export const fetchSubLayouts = createFetchAsyncThunk(
  'subLayout/fetchPages',
  'sub_layouts'
)

export const createSubLayout = createAsyncThunk(
  'subLayout/createSubLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { navigate, formData } = params
    delete params.navigate
    return fetch(
      `${baseUri}sub_layouts`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(async response => response.json())
      .then(toCamel)
      .then(payload => {
        const { subLayoutId } = payload
        dispatch(closeModal())
        navigate(`${baseUri}sub_layouts/${subLayoutId}/articles`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)

export const modifySubLayout = createAsyncThunk(
  'subLayout/modifySubLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { layoutableId, formData } = params

    return fetch(
      `${baseUri}sub_layouts/${layoutableId}`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: formData
      })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().page
        dispatch(fetchSubLayouts({ page: currentPage, sort }))
        dispatch(closeModal())
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const deleteSubLayout = createAsyncThunk(
  'subLayout/deleteSubLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(
        `${baseUri}sub_layouts/${id}`, {
          method: 'delete',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': csrfToken()
          },
          body: JSON.stringify(toSnake(params))
        })
      .then(throwErrorForErrorResponse)
      .then(() => {
        const { currentPage, sort } = getState().page
        dispatch(fetchSubLayouts({ page: currentPage, sort }))
        dispatch(closeModal())
        return Promise.resolve({})
      })
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const fetchLayoutResult = createAsyncThunk(
  'subLayout/layoutResult',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(
      `${baseUri}sub_layouts/${id}/layout_result`, {
        headers: {
          Accept: 'application/json'
        }
      })
      .then(throwTextErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)

export const exportLayout = createAsyncThunk(
  'subLayout/exportLayout',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { id } = params

    return fetch(`${baseUri}sub_layouts/${id}/export_layout.zip`)
      .then(throwErrorForErrorResponse)
      .then(response => response.blob())
      .catch(errorHandler(rejectWithValue))
  }
)

export const copySubLayout = createAsyncThunk(
  'subLayout/copySubLayout',
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { baseUri } = getState().common
    const { id } = params
    const { navigate } = params
    delete params.navigate

    return fetch(
      `${baseUri}sub_layouts/${id}/copy`, {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwErrorForErrorResponse)
      .then(response => response.json())
      .then(toCamel)
      .then(payload => {
        const { subLayoutId } = payload
        dispatch(closeModal())
        navigate(`${baseUri}sub_layouts/${subLayoutId}/articles`)
        return Promise.resolve({})
      })
      .catch(errorHandler(rejectWithValue))
  }
)

export const modifyLayoutIndex = createAsyncThunk(
  'subLayout/modifyLayoutIndex',
  async (params, { getState, rejectWithValue }) => {
    const { baseUri } = getState().common
    const { id } = params
    return fetch(
      `${baseUri}sub_layouts/${id}/layout_index`, {
        method: 'put',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken()
        },
        body: JSON.stringify(toSnake(params))
      })
      .then(throwTextErrorForErrorResponse)
      .then(async response => response.json())
      .then(toCamel)
      .catch(errorHandler(rejectWithValue))
  }
)
