import React, { useState } from 'react'
import Select from 'react-select'
import {
  Form,
  Row,
  Col,
  InputGroup,
  Modal,
  Button,
  Alert
} from 'react-bootstrap'
import { camelCase } from 'lodash'
import {
  handleBinaryFile,
  isLayoutEditableStatus
} from '../article'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

export const getPageMarginRelatedLists = (selectedSetting) => {
  const pageMarginSettings = selectedSetting?.pageMarginSettings
  const pageMarginOtherSettings = selectedSetting?.pageMarginOtherSettings
  const pageMarginRegionList = pageMarginSettings?.filter(s => s.pageMarginSettingType === 'region')?.map(s => s.name)
  const pageMarginOtherList = pageMarginSettings?.filter(s => s.pageMarginSettingType === 'other')
    ?.map((s) => {
      const keyName = pageMarginOtherSettings?.find(o => o.id === s.pageMarginOtherSettingId)?.name
      // console.log('other', s)
      return { value: s.id, label: `${keyName}:${s.name}` }
    })
  return { pageMarginSettings, pageMarginRegionList, pageMarginOtherList }
}
export const TemplateFileControl = ({
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  disabled
}) => {
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    const loadedBinaryAsync = handleBinaryFile(file)

    loadedBinaryAsync
      .then(value => {
        console.log('loadedBinaryFile', value)
        setFieldValue('loadedBinaryFile', value)
      })

    handleChange(e)
  }
  if (values?.templateFilename?.length > 0) {
    return (
      <Row>
        <Col sm={8}>
          <Form.Control
            size='sm'
            name='templateFilename'
            defaultValue={values.templateFilename}
            readOnly
            hidden={values.replaceFile}
          />
          <InputGroup>
            <Form.Control
              id='templateFileInput'
              name='templateFile'
              size='sm'
              type='file'
              accept='.indt'
              value={values.templateFile ?? ''}
              onChange={handleFileChange}
              onBlur={handleBlur}
              hidden={!values.replaceFile}
              disabled={disabled}
            />
          </InputGroup>
        </Col>
        <Col sm={4}>
          <Col>
            <Form.Check
              type='switch'
              id='replaceFileSwitch'
              label='変更'
              name='replaceFile'
              value={values.replaceFile ?? false}
              checked={values.replaceFile ?? false}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disabled || values.deleteFile}
              inline
            />
            <Form.Check
              type='switch'
              id='deleteFileSwitch'
              label='削除'
              name='deleteFile'
              value={values.deleteFile ?? false}
              checked={values.deleteFile ?? false}
              onChange={e => {
                setFieldValue('replaceFile', false)
                handleChange(e)
              }}
              onBlur={handleBlur}
              disabled={disabled}
              inline
            />
          </Col>
        </Col>
      </Row>
    )
  } else {
    return (
      <InputGroup>
        <Form.Control
          id='templateFileInput'
          name='templateFile'
          size='sm'
          type='file'
          accept='.indt'
          values={values.templateFile ?? ''}
          onChange={handleFileChange}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </InputGroup>
    )
  }
}
export const PageUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  setFieldValue,
  fetching,
  enumValues,
  onClickCancel,
  title,
  submitButtonText,
  mediaList,
  settingList,
  userList,
  currentUser,
  // pageMarginOtherSelections,
  alert
}) => {
  // console.log('foo', { pageMarginOtherSelections: values.pageMarginOtherSelections })
  const settingId = values.settingId
  const selectedSetting = values.selectedSettingList?.find(s => s.id === settingId)
  const { pageMarginSettings: settings, pageMarginRegionList: regionList, pageMarginOtherList: otherList } = getPageMarginRelatedLists(selectedSetting)

  const selectionIds = new Set(values.pageMarginOtherSelections?.map(s => s.pageMarginSettingId) || [])
  const defaultOtherList = otherList?.filter(o => selectionIds.has(o.value)) || []
  // console.log('fooooo', { otherList, selectionIds, defaultOtherList })

  const [pageMarginSettings, setPageMarginSettings] = useState(settings)
  const [pageMarginRegionList, setPageMarginRegionList] = useState(regionList)
  const [pageMarginOtherList, setPageMarginOtherList] = useState(otherList)
  // status is not set for newly created page
  const isLayoutableWritable = values.status === undefined || isLayoutEditableStatus(values.status)

  const setSelectedSetting = (selectedSetting) => {
    const selectedColumnSetting = selectedSetting?.columnSetting
    // console.log('selectedSetting', { selectedSetting, selectedColumnSetting })
    setFieldValue('columnSetting', selectedColumnSetting?.typeOf || '')
    setFieldValue('numberOfColumns', selectedColumnSetting?.numberOfColumns || '')
    setFieldValue('numberOfRows', selectedColumnSetting?.numberOfRows || '')
    setFieldValue('numberOfCharactersPerRow', selectedColumnSetting?.numberOfCharactersPerRow || '')
    const { pageMarginSettings: settings, pageMarginRegionList: regionList, pageMarginOtherList: otherList } = getPageMarginRelatedLists(selectedSetting)
    setPageMarginSettings(settings)
    setPageMarginRegionList(regionList)
    setPageMarginOtherList(otherList)
    if (regionList?.length > 0) {
      setFieldValue('pageMarginRegion', regionList[0])
    } else {
      setFieldValue('pageMarginRegion', '')
    }
    if (otherList?.length > 0) {
      setFieldValue('pageMarginOther', otherList[0])
    } else {
      setFieldValue('pageMarginOther', '')
    }
  }
  const handleChangeSetting = (e) => {
    const settingId = parseInt(e.target.value)
    const selectedSetting = values.selectedSettingList?.find(s => s.id === settingId)
    // console.log('handleChangeSetting', { selectedSetting })
    setSelectedSetting(selectedSetting)
    handleChange(e)
  }
  const handleChangePageNumber = (e) => {
    const pageNumber = parseInt(e.target.value)
    const isEven = (pageNumber % 2) === 0
    const pageType = isEven ? 'rightPageSpread' : 'leftPageSpread'
    setFieldValue('pageType', pageType)
    handleChange(e)
  }
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {title}
          {!isLayoutableWritable && <span className='text-secondary'> ({enumValues.statuses[camelCase(values.status)]}のため編集不可)</span>}
        </Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-page-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            <Form.Group as={Col}>
              <Form.Label column className='mediaIdLabel'>媒体名</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='mediaId'
                  size='sm'
                  value={values.mediaId}
                  onChange={(e) => {
                    const mediaId = parseInt(e.target.value)
                    const selectedSettingList = settingList?.filter(s => s.media.id === mediaId) || []
                    const settingId = selectedSettingList?.length > 0 ? selectedSettingList[0].id : ''
                    const selectedSetting = selectedSettingList?.find(s => s.id === settingId)
                    setFieldValue('selectedSettingList', selectedSettingList)
                    setFieldValue('settingId', settingId)
                    setSelectedSetting(selectedSetting)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.mediaId && errors.mediaId}
                  disabled={!isLayoutableWritable || mediaList?.length <= 1}
                >
                  {mediaList?.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.mediaId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='mediaIdLabel'>紙面ベース</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='settingId'
                  size='sm'
                  value={values.settingId}
                  onChange={handleChangeSetting}
                  onBlur={handleBlur}
                  isInvalid={touched.settingId && errors.settingId}
                  disabled={!isLayoutableWritable || errors.mediaId || settingList?.length <= 1}
                >
                  {values.selectedSettingList?.map(({ id, name }) => (
                    <option key={id} value={id}>{name}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.settingId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label column>段組</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='columnSetting'
                  size='sm'
                  value={values.columnSetting}
                  onChange={event => {
                    if (event.target.value === 'regular') {
                      // _.forEach(regularColumnSetting, _.rearg(setFieldValue, [1, 0]))
                      // TODO: copy from page base setting
                    }
                    handleChange(event)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.columnSetting && errors.columnSetting}
                >
                  {Object.keys(enumValues.columnSettings).map(k => (<option key={k} value={k}>{enumValues.columnSettings[k]}</option>))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.columnSetting}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>字数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfCharactersPerRow'
                  size='sm'
                  value={values.numberOfCharactersPerRow}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfCharactersPerRow && errors.numberOfCharactersPerRow}
                  disabled={!isLayoutableWritable || values.columnSetting === 'regular'}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfCharactersPerRow}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>行数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfRows'
                  size='sm'
                  value={values.numberOfRows}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfRows && errors.numberOfRows}
                  disabled={!isLayoutableWritable || values.columnSetting === 'regular'}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfRows}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>段数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='numberOfColumns'
                  size='sm'
                  value={values.numberOfColumns}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.numberOfColumns && errors.numberOfColumns}
                  disabled={!isLayoutableWritable || values.columnSetting === 'regular'}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.numberOfColumns}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          {
            currentUser.role === 'group_administrator' &&
              <Form.Group as={Row}>
                <Form.Label column className='col-2'>担当者</Form.Label>
                <Col>
                  <Form.Control
                    as='select'
                    type='text'
                    name='assigneeId'
                    size='sm'
                    value={values.assigneeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.assigneeId && errors.assigneeId}
                    disabled={!isLayoutableWritable || userList?.length <= 1}
                  >
                    {userList?.map(({ id, name }) => (
                      <option key={id} value={id}>{name}</option>
                    ))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors.assigneeId}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
          }
          <Row className='mb-3'>
            <Form.Group as={Col}>
              <Form.Label column className='issueDateLabel'>発行日</Form.Label>
              <Col>
                <Form.Control
                  type='date'
                  name='issueDate'
                  size='sm'
                  value={values.issueDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.issueDate && errors.issueDate}
                  disabled={!isLayoutableWritable}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.issueDate}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='issueNumberLabel'>号数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='issueNumber'
                  size='sm'
                  value={values.issueNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.issueNumber && errors.issueNumber}
                  disabled={!isLayoutableWritable}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.issueNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='pageNumberLabel'>面数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='pageNumber'
                  size='sm'
                  value={values.pageNumber}
                  onChange={handleChangePageNumber}
                  onBlur={handleBlur}
                  isInvalid={touched.pageNumber && errors.pageNumber}
                  disabled={!isLayoutableWritable}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.pageNumber}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column className='pageRevisionLabel'>版数</Form.Label>
              <Col>
                <Form.Control
                  type='number'
                  name='pageRevision'
                  size='sm'
                  value={values.pageRevision}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.pageRevision && errors.pageRevision}
                  disabled={!isLayoutableWritable}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.pageRevision}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>紙面種別</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='pageType'
                  size='sm'
                  value={values.pageType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.pageType && errors.pageType}
                  disabled={!isLayoutableWritable}
                >
                  {Object.keys(enumValues.pageTypes).map(k => (<option key={k} value={k}>{enumValues.pageTypes[k]}</option>))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.pageType}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Row>
          {(pageMarginSettings !== undefined && pageMarginSettings.length > 0) &&
            <Row className='mb-3'>
              <Form.Group as={Col}>
                <Form.Label column>欄外地方</Form.Label>
                <Col>
                  <Form.Control
                    as='select'
                    type='text'
                    name='pageMarginRegion'
                    size='sm'
                    value={values.pageMarginRegion}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.pageMarginRegion && errors.pageMarginRegion}
                    disabled={!isLayoutableWritable || pageMarginRegionList.length === 0}
                  >
                    <option value='' />
                    {pageMarginRegionList.map(n => (<option key={n} value={n}>{n}</option>))}
                  </Form.Control>
                  <Form.Control.Feedback type='invalid'>
                    {errors.pageMarginRegion}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label column>欄外その他</Form.Label>
                <Col>
                  <Select
                    name='pageMarginOtherSelections'
                    isMulti
                    closeMenuOnSelect={false}
                    options={pageMarginOtherList}
                    defaultValue={defaultOtherList}
                    onChange={selected => {
                      const pageMarginOtherSelections = selected?.map(s => s.value) ?? []
                      console.log('selected', { selected, pageMarginOtherSelections })
                      setFieldValue('pageMarginOtherSelections', pageMarginOtherSelections)
                    }}
                  />
                  <Form.Control.Feedback type='invalid'>
                    {errors.pageMarginOtherSelections}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Row>}
          <Row>
            <Form.Group as={Col} sm={3}>
              <Form.Label column>記事下広告分の余白</Form.Label>
              <Col>
                <InputGroup size='sm' hasValidation>
                  <Form.Control
                    id='bottomAdColumnsInput'
                    type='number'
                    name='bottomAdNumberOfColumns'
                    value={values.bottomAdNumberOfColumns}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.bottomAdNumberOfColumns && errors.bottomAdNumberOfColumns}
                    disabled={!isLayoutableWritable}
                  />
                  <InputGroup.Text>段</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>
                    {errors.bottomAdNumberOfColumns}
                  </Form.Control.Feedback>
                </InputGroup>
              </Col>
            </Form.Group>
            <Form.Group as={Col} sm={9}>
              <Form.Label column>紙面テンプレートファイル(indtファイル)</Form.Label>
              <Col>
                <TemplateFileControl
                  key='templateFileControl'
                  {...{ values, errors, handleBlur, handleChange, setFieldValue, disabled: !isLayoutableWritable }}
                />
              </Col>
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!isLayoutableWritable || !isValid || fetching}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}

PageUpsertForm.schema = Yup.object().shape({
  // name: Yup.string().required(),
  mediaId: Yup.number().required(),
  settingId: Yup.number().required(),
  columnSetting: Yup.string().required(),
  numberOfCharactersPerRow: Yup.number().required().min(10).max(30),
  numberOfRows: Yup.number().required().min(30).max(100),
  numberOfColumns: Yup.number().required().min(1).max(15),
  assigneeId: Yup.number().required(),
  pageType: Yup.string().required(),
  issueDate: Yup.date().required(),
  issueNumber: Yup.number().required().min(1),
  pageNumber: Yup.number().required().min(1),
  pageRevision: Yup.number().required().min(1),
  pageMarginRegion: Yup.string(),
  pageMarginOtherSelections: Yup.array().of(Yup.number()),
  bottomAdNumberOfColumns: Yup.number().required().min(0),
  templateFilename: Yup.string().nullable(true),
  deleteFile: Yup.boolean(),
  status: Yup.string().nullable(true)
})
