import React from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Form,
  InputGroup,
  Row,
  Col
} from 'react-bootstrap'
import * as Yup from 'yup'
import * as ja from 'yup-locale-ja'
import {
  selectCell
} from './articleSlice'
import {
  modifyArea
} from './articleAPI'
import {
  nullishValueToInternalValue,
  nullishValueToValue,
  toPosDisplayValue,
  toRightPosValue
} from '../article'
Yup.setLocale(ja.suggestive)

export const ArticleAreaForm = ({
  values,
  errors,
  touched,
  isValid,
  handleChange,
  setFieldValue,
  handleBlur,
  layoutableType,
  layoutableId,
  isSelectedLayoutableWritable,
  layoutable,
  article
}) => {
  const dispatch = useDispatch()
  // console.log('touched', touched)
  const inputDisabled = !isSelectedLayoutableWritable || article?.layoutLocked
  return (
    <Form
      noValidate
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(modifyArea({ layoutableType, layoutableId, area: values }))
        dispatch(selectCell(null))
      }}
    >
      <Row>
        <Form.Group as={Col} className='mb-2'>
          <Form.Label column>記事開始位置</Form.Label>
          <Row>
            <Form.Group as={Col}>
              <InputGroup size='sm' hasValidation>
                <InputGroup.Text>天から</InputGroup.Text>
                <Form.Control
                  type='number'
                  name='y'
                  value={nullishValueToValue(values?.y)}
                  placeholder='ナリ'
                  onChange={(e) => {
                    const y = nullishValueToInternalValue(e.target.value)
                    setFieldValue('y', y)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.y && errors.y}
                  disabled={inputDisabled}
                />
                <InputGroup.Text>段目</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  {errors.y}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup size='sm' hasValidation>
                <InputGroup.Text>{!values?.fromLeft ? '右' : '左'}から</InputGroup.Text>
                <Form.Control
                  type='number'
                  name='x'
                  value={toPosDisplayValue(layoutable, values)}
                  placeholder='ナリ'
                  onChange={(e) => {
                    const { numberOfRows } = layoutable
                    const internalValue = nullishValueToInternalValue(e.target.value)
                    const x = values.fromLeft ? toRightPosValue(numberOfRows, internalValue) : internalValue
                    setFieldValue('x', x)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.x && errors.x}
                  disabled={inputDisabled}
                />
                <InputGroup.Text>行目</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  {errors.x}
                </Form.Control.Feedback>
              </InputGroup>
              <Form.Check
                type='switch'
                id='fromLeftSwitch'
                label='左から'
                name='fromLeft'
                size='sm'
                value={values.fromLeft}
                checked={values.fromLeft}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.article?.areas?.[0]?.fromLeft && errors.article?.areas?.[0]?.fromLeft}
                disabled={inputDisabled}
              />
            </Form.Group>
          </Row>
        </Form.Group>
      </Row>
      <Row>
        <Form.Group as={Col} className='mb-2'>
          <Form.Label column>記事サイズ</Form.Label>
          <Row>
            <Form.Group as={Col}>
              <InputGroup size='sm' hasValidation>
                <InputGroup.Text>天地</InputGroup.Text>
                <Form.Control
                  type='number'
                  name='height'
                  value={parseInt(values.height)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.height && errors.height}
                  disabled={inputDisabled}
                />
                <InputGroup.Text>段</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  {errors.height}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col}>
              <InputGroup size='sm' hasValidation>
                <InputGroup.Text>左右</InputGroup.Text>
                <Form.Control
                  type='number'
                  name='width'
                  value={parseInt(values.width)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.width && errors.width}
                  disabled={inputDisabled}
                />
                <InputGroup.Text>行幅</InputGroup.Text>
                <Form.Control.Feedback type='invalid'>
                  {errors.width}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>
        </Form.Group>
      </Row>
      <br />
      <Row>
        <Col sm='auto'>
          <Button
            type='submit'
            size='sm'
            disabled={inputDisabled || !isValid || !Object.keys(touched).length}
          >
            保存
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

const areaXErrorMessage = '右から1以上にしてください'
const areaYErrorMessage = '1以上にしてください'

ArticleAreaForm.schema = Yup.object().shape({
  isPositionRequired: Yup.boolean(),
  x: Yup.number().min(0, areaXErrorMessage).when('isPositionRequired', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(true)
  }),
  y: Yup.number().min(0, areaYErrorMessage).when('isPositionRequired', {
    is: true,
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.nullable(true)
  }),
  width: Yup.number().required().min(1),
  height: Yup.number().required().min(1),
  fromLeft: Yup.boolean().required()
})
