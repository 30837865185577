import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import {
  Col,
  Container,
  Image,
  Row
} from 'react-bootstrap'
import {
  CustomModal,
  ErrorModal,
  selectCommon
} from '../common'
import {
  getCreateFormSetting as getCreatePageFormSetting,
  getHandleCreatePageSubmit
} from '../page'
import {
  getCreateFormSetting as getCreateSubLayoutFormSetting,
  getHandleCreateSubLayoutSubmit
} from '../subLayout'
import {
  fetchMedia,
  selectMedia,
  fetchSettings,
  selectSetting
} from '../media'
import {
  selectHome,
  openModal,
  closeModal,
  dismissError
} from './homeSlice'
import {
  selectUser,
  fetchUsers
} from '../user'
import {
  createPage,
  createSubLayout,
  fetchMessage
} from './homeAPI'
import {
  administratorRelatedFeaturesGranted,
  selectAuth,
  shouldShowExperimentalFeatures
} from '../auth'

const ToggleLink = ({
  dispatch,
  linkId,
  modalName,
  label,
  fetching
}) => (
  fetching
    ? <>{label}</>
    : <Link id={linkId} to='#' onClick={() => dispatch(openModal(modalName))}>{label}</Link>
)

export const HomeScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { currentUser } = useSelector(selectAuth)
  useEffect(() => {
    dispatch(fetchMedia({ all: true }))
    dispatch(fetchSettings({ all: true }))
    dispatch(fetchUsers({}))
  }, [])
  const {
    fetching: homeFetching,
    openedModal,
    errors,
    alert
  } = useSelector(selectHome)
  useEffect(() => {
    dispatch(fetchMessage())
  }, [dispatch])
  const {
    data: mediaList,
    fetching: mediaFetching
  } = useSelector(selectMedia)
  const {
    data: settingList,
    fetching: settingFetching
  } = useSelector(selectSetting)
  const {
    data: userList,
    fetching: userFetching
  } = useSelector(selectUser)
  const [fetching, setFetching] = useState(true)
  useEffect(() => {
    if (homeFetching || mediaFetching || settingFetching || userFetching) {
      setFetching(true)
    } else {
      setFetching(false)
    }
  }, [homeFetching, mediaFetching, settingFetching, userFetching])
  const handleCreatePageSubmit = getHandleCreatePageSubmit(dispatch, navigate, createPage)
  const handleCreateSubLayoutSubmit = getHandleCreateSubLayoutSubmit(dispatch, navigate, createSubLayout)
  const { enumValues } = useSelector(selectCommon)
  const createPageFormSetting = {
    name: 'create-page',
    form: getCreatePageFormSetting({
      handleCreateSubmit: handleCreatePageSubmit,
      fetching,
      enumValues,
      closeModal,
      dispatch,
      mediaList,
      settingList,
      userList,
      currentUser,
      alert
    })
  }
  const createSubLayoutFormSetting = {
    name: 'create-subLayout',
    form: getCreateSubLayoutFormSetting({
      handleCreateSubmit: handleCreateSubLayoutSubmit,
      fetching,
      enumValues,
      closeModal,
      dispatch,
      mediaList,
      settingList,
      userList,
      currentUser,
      alert
    })
  }
  const colorStyle = {
    color: '#19448a'
  }
  const footerStyle = {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '96px'
  }
  const showExperimentalFeatures = shouldShowExperimentalFeatures()
  const isAdministratorRelatedFeaturesGranted = administratorRelatedFeaturesGranted()
  return (
    <>
      <ErrorModal
        errors={errors}
        onClose={errorKey => dispatch(dismissError(errorKey))}
      />
      <Container fluid>
        <Row className='align-items-center' style={{ height: '500px' }}>
          <Col sm={5} className='justify-content-center text-center'>
            <Image fluid src='newspaper_ai_layout_logo.jpg' alt='logo' />
          </Col>
          <Col sm={7} className='justify-content-center display-6' style={{ ...colorStyle }}>
            <ul>
              <li>
                <ToggleLink fetching={fetching} dispatch={dispatch} linkId='createPageLink' modalName='create-page' label='新規割付用出稿表' />
              </li>
              {showExperimentalFeatures &&
                <li>
                  <ToggleLink fetching={fetching} dispatch={dispatch} linkId='createSubLayoutLink' modalName='create-subLayout' label='小組割付用出稿表' />
                </li>}
              <li>
                <Link id='pageListLink' to='/pages'>紙面一覧</Link>
              </li>
              {showExperimentalFeatures &&
                <li>
                  <Link id='pageListLink' to='/sub_layouts'>小組一覧</Link>
                </li>}
              {isAdministratorRelatedFeaturesGranted &&
                <li>
                  <Link id='settingListLink' to='/settings'>紙面ベース一覧</Link>
                </li>}
              {isAdministratorRelatedFeaturesGranted &&
                <li>
                  <Link id='mediaListLink' to='/media'>媒体一覧</Link>
                </li>}
              {isAdministratorRelatedFeaturesGranted &&
                <li>
                  <Link id='groupListLink' to='/groups'>グループ一覧</Link>
                </li>}
              {isAdministratorRelatedFeaturesGranted &&
                <li>
                  <Link id='userListLink' to='/users'>ユーザー一覧</Link>
                </li>}
            </ul>
          </Col>
        </Row>
      </Container>
      <CustomModal key='create-page-modal' {...createPageFormSetting} openedModal={openedModal} />
      <CustomModal key='create-subLayout-modal' {...createSubLayoutFormSetting} openedModal={openedModal} />
      <footer className='footer' style={footerStyle}>
        <Container fluid>
          <Row className='footer-disclaimer'>
            <Col className='text-end'>
              R2 事業再構築 機-1 ※事業再構築補助金事業以外での使用禁止
            </Col>
          </Row>
          <Row className='align-items-center footer-bottom' style={{ ...colorStyle, ...{ backgroundColor: '#23b8bc' } }}>
            <Col className='fs-1 fw-bold' sm='auto'>
              新聞AI割付
            </Col>
            <Col className='mr-auto'>
              本プログラムは、提供された素材を元に AI が自動で割り付けをするものです<br />
              割り付けされた紙面は組版情報として書き出します
            </Col>
            <Col className='justify-content-sm-end' sm='auto'>
              <Row><Col className='display-6 fw-bold'>SSC</Col></Row>
              <Row><Col className='fs-6'>新聞制作センター</Col></Row>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}
