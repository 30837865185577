import React from 'react'
import {
  Form,
  InputGroup,
  Row,
  Col,
  Modal,
  Button,
  Alert
} from 'react-bootstrap'
import * as Yup from 'yup'
import { snakeCase } from 'lodash'
import { shouldShowExperimentalFeatures } from '../auth'
import {
  parseArticleFile,
  defaultAreaSize,
  createArticle,
  handleBinaryFile,
  getDefaultAuxiliaryLine,
  getDefaultDividerLineMode,
  changeArticlePriority,
  nullish,
  nullishValueToInternalValue,
  nullishValueToValue,
  isPositionRequired,
  isAreaArticleType,
  isSizedArticleType,
  isTopPositionPriority,
  toPosDisplayValue,
  toRightPosValue,
  isDisabledArticleType,
  coerceArticleTypeOnPriorityChange,
  ArticleType,
  Priority,
  RelationType
} from '../article'
import * as ja from 'yup-locale-ja'
Yup.setLocale(ja.suggestive)

const AreaArticleFilenameInput = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleFileChange
}) => {
  const acceptedTypes = [
    '.png', '.gif', '.jpeg', '.jpg', '.psd',
    '.eps',
    '.indd'
  ]
  if (values?.filename?.length > 0) {
    return (
      <Row className='mb-2'>
        <Form.Group as={Col}>
          <Form.Label column>領域ファイル</Form.Label>
          <Row>
            <Col sm={8}>
              <Form.Control
                name='filename'
                size='sm'
                defaultValue={values.filename}
                readOnly
                hidden={values.replaceFile}
              />
              <InputGroup>
                <Form.Control
                  id='areaFileInput'
                  name='file'
                  size='sm'
                  type='file'
                  accept={acceptedTypes}
                  value={values.file ?? ''}
                  onChange={handleFileChange}
                  onBlur={handleBlur}
                  hidden={!values.replaceFile}
                />
              </InputGroup>
            </Col>
            <Col sm={4}>
              <Col>
                <Form.Check
                  type='switch'
                  id='replaceFileSwitch'
                  label='変更'
                  name='replaceFile'
                  value={values.replaceFile ?? false}
                  checked={values.replaceFile ?? false}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.deleteFile}
                  inline
                />
                <Form.Check
                  type='switch'
                  id='deleteFileSwitch'
                  label='削除'
                  name='deleteFile'
                  value={values.deleteFile ?? false}
                  checked={values.deleteFile ?? false}
                  onChange={e => {
                    setFieldValue('replaceFile', false)
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  inline
                />
              </Col>
            </Col>
          </Row>
        </Form.Group>
      </Row>
    )
  } else {
    return (
      <Row className='mb-2'>
        <Form.Group as={Col}>
          <Form.Label column>領域ファイル</Form.Label>
          <Col>
            <Form.Control
              id='file'
              type='file'
              name='file'
              accept={acceptedTypes}
              value={values.file}
              onChange={handleFileChange}
              onBlur={(e) => e.preventDefault()}
              disabled={!isAreaArticleType(values.articleType)}
              isInvalid={touched.file && errors.file}
            />
            <Form.Control.Feedback type='invalid' className='invalid-file'>
              {errors.file}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      </Row>
    )
  }
}

const AreaArticleAreaInput = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  layoutable
}) => (
  <Row>
    <Form.Group as={Col} sm={3}>
      <Form.Label>天から</Form.Label>
      <InputGroup size='sm' hasValidation>
        <Form.Control
          type='number'
          name='article.areas[0].y'
          value={nullishValueToValue(values.article?.areas?.[0]?.y)}
          placeholder='ナリ'
          onChange={(e) => {
            // console.log('onChange for article.areas[0].y called')
            const areas = [{ ...values.article?.areas?.[0] }]
            areas[0].y = nullishValueToInternalValue(e.target.value)
            const newArticle = {
              ...values.article,
              areas
            }
            setFieldValue('article', newArticle)
          }}
          onBlur={handleBlur}
          isInvalid={touched.article?.areas?.[0]?.y && errors.article?.areas?.[0]?.y}
        />
        <InputGroup.Text>段目</InputGroup.Text>
        <Form.Control.Feedback type='invalid'>
          {errors.article?.areas?.[0]?.y}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label as={Col}>{!values.article?.areas?.[0]?.fromLeft ? '右' : '左'}から</Form.Label>
      <InputGroup size='sm' hasValidation>
        <Form.Control
          type='number'
          name='article.areas[0].x'
          value={toPosDisplayValue(layoutable, values?.article?.areas?.[0])}
          placeholder='ナリ'
          onChange={(e) => {
            // console.log('onChange for article.areas[0].x called')
            const { numberOfRows } = layoutable
            const areas = [{ ...values.article?.areas?.[0] }]
            const internalValue = nullishValueToInternalValue(e.target.value)
            const fromLeft = values.article?.areas?.[0]?.fromLeft
            areas[0].x = fromLeft ? toRightPosValue(numberOfRows, internalValue) : internalValue
            const newArticle = {
              ...values.article,
              areas
            }
            setFieldValue('article', newArticle)
          }}
          onBlur={handleBlur}
          isInvalid={touched.article?.areas?.[0]?.x && errors.article?.areas?.[0]?.x}
        />
        <InputGroup.Text>行目</InputGroup.Text>
        <Form.Control.Feedback type='invalid'>
          {errors.article?.areas?.[0]?.x}
        </Form.Control.Feedback>
      </InputGroup>
      <Form.Check
        type='switch'
        id='fromLeftSwitch'
        label='左から'
        name='article.areas[0].fromLeft'
        size='sm'
        value={values.article?.areas?.[0]?.fromLeft ?? false}
        checked={values.article?.areas?.[0]?.fromLeft ?? false}
        onChange={(e) => {
          const areas = [{ ...values.article?.areas?.[0] }]
          areas[0].fromLeft = e.target.checked
          const newArticle = {
            ...values.article,
            areas
          }
          setFieldValue('article', newArticle)
        }}
        onBlur={handleBlur}
        isInvalid={touched.article?.areas?.[0]?.fromLeft && errors.article?.areas?.[0]?.fromLeft}
      />
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label>天地</Form.Label>
      <InputGroup size='sm' hasValidation>
        <Form.Control
          type='number'
          name='article.areas[0].height'
          value={parseInt(values.article?.areas?.[0]?.height) || ''}
          onChange={(e) => {
            const areas = [{ ...values.article?.areas?.[0] }]
            areas[0].height = e.target.value
            const newArticle = {
              ...values.article,
              areas
            }
            setFieldValue('article', newArticle)
          }}
          onBlur={handleBlur}
          isInvalid={touched.article?.areas?.[0]?.height && errors.article?.areas?.[0]?.height}
        />
        <InputGroup.Text>段</InputGroup.Text>
        <Form.Control.Feedback type='invalid'>
          {errors.article?.areas?.[0]?.height}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
    <Form.Group as={Col} sm={3}>
      <Form.Label as={Col}>左右</Form.Label>
      <InputGroup size='sm' hasValidation>
        <Form.Control
          type='number'
          name='article.areas[0].width'
          value={parseInt(values.article?.areas?.[0]?.width) || ''}
          onChange={(e) => {
            const areas = [{ ...values.article?.areas?.[0] }]
            areas[0].width = e.target.value
            const newArticle = {
              ...values.article,
              areas
            }
            setFieldValue('article', newArticle)
          }}
          onBlur={handleBlur}
          isInvalid={touched.article?.areas?.[0]?.width && errors.article?.areas?.[0]?.width}
        />
        <InputGroup.Text>行幅</InputGroup.Text>
        <Form.Control.Feedback type='invalid'>
          {errors.article?.areas?.[0]?.width}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  </Row>
)

export const ArticleUpsertForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isValid,
  largestOrder,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  enumValues,
  onClickCancel,
  layoutable,
  title,
  submitButtonText,
  usedPriorities,
  nonHolderArticles,
  selectedArticle,
  existingFiles,
  alert
}) => {
  if (!values) { return }
  const formProps = { values, errors, touched, handleChange, handleBlur, isValid, setFieldValue, layoutable }
  const fileReader = new window.FileReader()
  const updateArticleObject = (
    article,
    layoutable,
    parsedObject
  ) => {
    const newArticle = createArticle(article, layoutable, parsedObject, true)
    newArticle.order = article.priority === Priority.Other
      ? (article.order ?? (largestOrder + 1))
      : 0
    return newArticle
  }

  const parentTargetArticles = nonHolderArticles
    ?.map((a, rowIdx) => ({ ...a, rowIdx, optionName: `${rowIdx + 1} - ${a?.name || a?.filename || ''}` }))
    ?.filter(a => a.id !== selectedArticle.id && a.parentId === null)
  // console.log('nonHolderArticles', nonHolderArticles)
  // console.log('selectedArticle', selectedArticle)
  const childrenArticles = nonHolderArticles
    ?.filter(a => a.parentId !== null)
    ?.reduce((acc, val) => {
      const parentId = val.parentId
      const arr = (acc?.[parentId] ?? [])
      arr.push(val)
      acc[parentId] = arr
      return acc
    }, {})

  const isDisabledRelationTypeOption = (relationType) => {
    if (nullish(values?.parentId)) {
      return false
    }
    const otherChildrenRelationTypes = childrenArticles?.[values.parentId]
      ?.filter(a => a.id !== selectedArticle.id)
      ?.map(a => a.relationType) ?? []
    if (otherChildrenRelationTypes.length === 0) {
      return false
    }
    if (otherChildrenRelationTypes.some(rt => rt === RelationType.Same)) {
      return relationType !== RelationType.Same
    } else {
      return relationType === RelationType.Same
    }
  }

  const getDefaultRelationType = (parentId) => {
    const otherChildrenRelationTypes = childrenArticles?.[parentId]
      ?.filter(a => a.id !== selectedArticle.id)
      ?.map(a => a.relationType)
    if (otherChildrenRelationTypes?.length > 0 && otherChildrenRelationTypes.some(rt => rt === RelationType.Same)) {
      return RelationType.Same
    }
    return RelationType.Related
  }
  // console.log('childrenArticles', childrenArticles)
  // console.log('parentTargetArticles', parentTargetArticles)
  // console.log('enumValues.relationTypes', enumValues.relationTypes)
  const handleFileChange = (e) => {
    const file = e.target.files[0]
    if (!file) return

    const filename = file.name.normalize('NFC')
    if (existingFiles?.length > 0 && existingFiles.includes(filename) && filename !== values.filename) {
      setFieldValue('file', '', false)
      setFieldTouched('file', true, false)
      setFieldError('file', `ファイル「${filename}」はすでに使用されています。`)
      return
    }
    if (values.articleType !== ArticleType.Area) {
      // non area articles use text input
      fileReader.onloadend = () => {
        const parsedResult = parseArticleFile({ name: filename, content: fileReader.result })
        if (Array.isArray(parsedResult)) {
          // not supported
          setFieldValue('file', '', false)
          setFieldTouched('file', true, false)
          setFieldError('file', `ファイル「${filename}」のフォーマットが不正です。`)
          console.log('array', { parsedResult })
          return
        }
        const newArticle = {
          ...values.article,
          ...{
            filename,
            priority: values.priority,
            articleType: values.articleType,
            columnSpaceExistence: values.columnSpaceExistence,
            dividerLineMode: values.dividerLineMode
          }
        }
        // console.log('newArticle in file parse', newArticle)
        const article = updateArticleObject(newArticle, layoutable, parsedResult)
        // console.log('article after file parse', article)
        setFieldValue('filename', filename)
        setFieldValue('article', article)
      }
      fileReader.readAsText(file, 'utf8')
    } else {
      // existingFiles={existingFiles}
      const loadedBinaryAsync = handleBinaryFile(file)
      loadedBinaryAsync
        .then(value => {
          console.log('loadedBinaryFile', value)
          setFieldValue('loadedBinaryFile', value)
        })
    }
    handleChange(e)
  }
  const showExperimentalFeatures = shouldShowExperimentalFeatures()
  const handleChangeArticleType = (e) => {
    const articleType = e.target.value
    const priority = articleType === ArticleType.Template ? Priority.Other : values.priority
    const order = priority === Priority.Other
      ? (values.article?.order ?? (largestOrder + 1))
      : 0
    if (nullish(values.article) ||
        (isAreaArticleType(values.article?.articleType) && !isAreaArticleType(articleType)) ||
        (!isAreaArticleType(values.article?.articleType) && isAreaArticleType(articleType))) {
      setFieldValue('dividerLineMode', getDefaultDividerLineMode(articleType))
    }
    if (isAreaArticleType(articleType)) {
      const existingAreas = (isAreaArticleType(values.article?.articleType) && values.article?.areas?.length === 1) ? values.article?.areas : null
      if (articleType === ArticleType.Template) {
        setFieldValue('priority', Priority.Other)
      }
      const positionRequired = isPositionRequired(articleType)
      const defaultAreaValue = positionRequired
        ? { ...defaultAreaSize, x: 0, y: 0, fromLeft: false }
        : { ...defaultAreaSize, x: '', y: '', fromLeft: false }
      const areas = existingAreas ?? [defaultAreaValue]
      const newArticle = {
        ...values.article,
        ...{
          articleType,
          priority,
          order,
          areas,
          columnSpaceExistence: values.columnSpaceExistence,
          dividerLineMode: values.dividerLineMode
        }
      }
      // console.log('newArticle with area type', newArticle)
      setFieldValue('article', newArticle)
    } else if (values.article) {
      // console.log('articleType specified', articleType)
      const newArticle = {
        ...values.article,
        ...{
          articleType
        }
      }
      if (isSizedArticleType(articleType) && isTopPositionPriority(priority)) {
        newArticle.areas = [{ x: '', y: 0, width: '', height: '', fromLeft: false }]
      } else {
        newArticle.areas = undefined
      }
      setFieldValue('article', newArticle)
    } else if (isSizedArticleType(articleType) && isTopPositionPriority(priority)) {
      const newArticle = {
        articleType,
        priority,
        order,
        areas: [{ x: '', y: 0, width: '', height: '', fromLeft: false }],
        columnSpaceExistence: values.columnSpaceExistence,
        dividerLineMode: values.dividerLineMode
      }
      console.log('newArticle', newArticle)
      setFieldValue('article', newArticle)
    }
    handleChange(e)
  }
  const articleName = values?.filename || values?.name

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {title}
          {articleName && <span className='text-secondary'> ({articleName})</span>}
        </Modal.Title>
      </Modal.Header>
      {alert?.show && alert?.alertBody &&
        <Alert
          className='upsert-page-errors-alert'
          variant='danger'
        >
          {alert.alertBody}
        </Alert>}
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Body>
          <Row className='mb-2'>
            <Form.Group as={Col}>
              <Form.Label column>記事種別</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='articleType'
                  size='sm'
                  value={values.articleType}
                  onChange={handleChangeArticleType}
                  onBlur={handleBlur}
                  disabled={values.articleType === ArticleType.Template}
                  isInvalid={touched.articleType && errors.articleType}
                >
                  {Object.keys(enumValues.articleTypes).map(articleType => (
                    <option
                      key={articleType}
                      value={articleType}
                      disabled={isDisabledArticleType(values.priority, articleType)}
                    >
                      {enumValues.articleTypes[articleType]}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.articleType}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>記事優先度</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='priority'
                  size='sm'
                  value={values.priority}
                  onChange={(e) => {
                    const priority = e.target.value
                    const articleType = coerceArticleTypeOnPriorityChange(priority, values.articleType)
                    setFieldValue('articleType', articleType)
                    console.log('priority specified', { priority, valuesArticleType: values.articleType, coercedArticleType: articleType })
                    if (values.article) {
                      const newArticle = {
                        ...values.article,
                        ...{
                          priority
                        }
                      }
                      if (isSizedArticleType(articleType) && isTopPositionPriority(priority)) {
                        newArticle.areas = [{ x: '', y: 0, width: '', height: '', fromLeft: false }]
                      } else {
                        newArticle.areas = undefined
                      }
                      changeArticlePriority({ layoutable, article: newArticle, priority })
                      setFieldValue('article', newArticle)
                    } else if (isSizedArticleType(articleType) && isTopPositionPriority(priority)) {
                      const newArticle = {
                        articleType,
                        priority,
                        areas: [{ x: '', y: 0, width: '', height: '', fromLeft: false }],
                        order: values.order,
                        columnSpaceExistence: values.columnSpaceExistence,
                        dividerLineMode: values.dividerLineMode
                      }
                      console.log('newArticle', newArticle)
                      setFieldValue('article', newArticle)
                    }
                    handleChange(e)
                  }}
                  disabled={values.articleType === ArticleType.Template}
                  onBlur={handleBlur}
                  isInvalid={touched.priority && errors.priority}
                >
                  {Object.keys(enumValues.priorities).map(k => (
                    <option key={k} value={snakeCase(k)} disabled={usedPriorities.has(k)}>{enumValues.priorities[k]}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.priority}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            {!isAreaArticleType(values.articleType) &&
              <Form.Group as={Col}>
                <Form.Label column>中段線有無</Form.Label>
                <Col>
                  <Form.Check
                    type='switch'
                    id='columnSpaceExistenceSwitch'
                    label='中段線あり'
                    name='columnSpaceExistence'
                    value={values.columnSpaceExistence}
                    checked={values.columnSpaceExistence}
                    onChange={(e) => {
                      console.log('columnSpaceExistence', e.target.checked)
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.columnSpaceExistence && errors.columnSpaceExistence}
                  />
                </Col>
              </Form.Group>}
            {(isAreaArticleType(values.articleType) && showExperimentalFeatures) &&
              <Form.Group as={Col}>
                <Form.Label column>仕切り罫</Form.Label>
                <Col>
                  <Form.Control
                    as='select'
                    type='text'
                    label='仕切り罫'
                    name='dividerLineMode'
                    size='sm'
                    value={values.dividerLineMode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.dividerLineMode && errors.dividerLineMode}
                  >
                    {Object.keys(enumValues.dividerLineModes).map(k => (
                      <option key={k} value={snakeCase(k)}>{enumValues.dividerLineModes[k]}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>}
          </Row>
          {isSizedArticleType(values.articleType) &&
            <>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label column>記事開始位置指定</Form.Label>
                  <Row>
                    <Form.Group as={Col} sm={4}>
                      <InputGroup size='sm' hasValidation>
                        <InputGroup.Text>天から</InputGroup.Text>
                        <Form.Control
                          type='number' name='article.areas[0].y'
                          value={nullishValueToValue(values.article?.areas?.[0]?.y)}
                          placeholder='ナリ'
                          onChange={(e) => {
                            const areas = [{ ...values.article?.areas?.[0] }]
                            areas[0].y = nullishValueToInternalValue(e.target.value)
                            const newArticle = {
                              ...values.article,
                              areas
                            }
                            setFieldValue('article', newArticle)
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.article?.areas?.[0]?.y && errors.article?.areas?.[0]?.y}
                        />
                        <InputGroup.Text>段目</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.article?.areas?.[0]?.y}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <InputGroup size='sm' hasValidation>
                        <InputGroup.Text>右から</InputGroup.Text>
                        <Form.Control
                          type='number'
                          name='article.areas[0].x'
                          value={nullishValueToValue(values.article?.areas?.[0]?.x)}
                          placeholder='ナリ'
                          onChange={(e) => {
                            const areas = [{ ...values.article?.areas?.[0] }]
                            areas[0].x = nullishValueToInternalValue(e.target.value)
                            const newArticle = {
                              ...values.article,
                              areas
                            }
                            setFieldValue('article', newArticle)
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.article?.areas?.[0]?.x && errors.article?.areas?.[0]?.x}
                        />
                        <InputGroup.Text>行目</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.article?.areas?.[0]?.x}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                </Form.Group>
              </Row>
              <Row>
                <Form.Group as={Col}>
                  <Form.Label column>記事サイズ指定</Form.Label>
                  <Row>
                    <Form.Group as={Col} sm={4}>
                      <InputGroup size='sm' hasValidation>
                        <InputGroup.Text>高さ</InputGroup.Text>
                        <Form.Control
                          type='number'
                          name='article.areas[0].height'
                          value={parseInt(values.article?.areas?.[0]?.height) || ''}
                          placeholder='ナリ'
                          onChange={(e) => {
                            const areas = [{ ...values.article?.areas?.[0] }]
                            areas[0].height = e.target.value
                            const newArticle = {
                              ...values.article,
                              areas
                            }
                            setFieldValue('article', newArticle)
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.article?.areas?.[0]?.height && errors.article?.areas?.[0]?.height}
                        />
                        <InputGroup.Text>段</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.article?.areas?.[0]?.height}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <InputGroup size='sm' hasValidation>
                        <InputGroup.Text>横幅</InputGroup.Text>
                        <Form.Control
                          type='number'
                          name='article.areas[0].width'
                          value={parseInt(values.article?.areas?.[0]?.width) || ''}
                          placeholder='ナリ'
                          onChange={(e) => {
                            const areas = [{ ...values.article?.areas?.[0] }]
                            areas[0].width = e.target.value
                            const newArticle = {
                              ...values.article,
                              areas
                            }
                            setFieldValue('article', newArticle)
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.article?.areas?.[0]?.width && errors.article?.areas?.[0]?.width}
                        />
                        <InputGroup.Text>行</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.article?.areas?.[0]?.width}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} sm={4}>
                      <InputGroup size='sm' hasValidation>
                        <InputGroup.Text>行数</InputGroup.Text>
                        <Form.Control
                          type='number'
                          name='rows'
                          value={values?.rows ?? ''}
                          placeholder='ナリ'
                          onChange={(e) => {
                            handleChange(e)
                          }}
                          onBlur={handleBlur}
                          isInvalid={touched.rows && errors.rows}
                          aria-describedby='articleFixRowsHelp'
                        />
                        <InputGroup.Text>行</InputGroup.Text>
                        <Form.Control.Feedback type='invalid'>
                          {errors.rows}
                        </Form.Control.Feedback>
                      </InputGroup>
                      <Form.Text id='articleFixRowsHelp' muted>
                        (本文行数: {values.article?.bodyNumberOfRows} 行)
                      </Form.Text>
                    </Form.Group>
                  </Row>
                </Form.Group>
              </Row>
            </>}
          {values.articleType === ArticleType.Flow &&
            <Row>
              <Form.Group as={Col}>
                <Form.Label column>記事サイズ指定</Form.Label>
                <Row>
                  <Form.Group as={Col} sm={4}>
                    <InputGroup size='sm' hasValidation>
                      <InputGroup.Text>行数</InputGroup.Text>
                      <Form.Control
                        type='number'
                        name='rows'
                        value={values?.rows ?? ''}
                        placeholder='ナリ'
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        onBlur={handleBlur}
                        isInvalid={touched.rows && errors.rows}
                        aria-describedby='articleFixRowsHelp'
                      />
                      <InputGroup.Text>行</InputGroup.Text>
                      <Form.Control.Feedback type='invalid'>
                        {errors.rows}
                      </Form.Control.Feedback>
                    </InputGroup>
                    {values.article?.bodyNumberOfRows !== undefined &&
                      <Form.Text id='articleFixRowsHelp' muted>
                        (本文行数: {values.article?.bodyNumberOfRows} 行)
                      </Form.Text>}
                  </Form.Group>
                </Row>
              </Form.Group>
            </Row>}
          <Row className='mb-2'>
            <Form.Group as={Col}>
              <Form.Label column>補助線</Form.Label>
              <Row>
                <Form.Group as={Col} sm='auto'>
                  <Form.Check
                    type='switch'
                    id='useAuxiliaryLineSwitch'
                    label='補助線あり'
                    name='useAuxiliaryLine'
                    value={values.useAuxiliaryLine}
                    checked={values.useAuxiliaryLine}
                    onChange={(e) => {
                      const useAuxiliaryLine = e.target.checked
                      if (useAuxiliaryLine && values.auxiliaryLine === null) {
                        const auxiliaryLine = getDefaultAuxiliaryLine({ ...values, layoutable })
                        console.log('auxiliaryLine', auxiliaryLine)
                        setFieldValue('auxiliaryLine', auxiliaryLine)
                      } else if (!useAuxiliaryLine) {
                        setFieldValue('auxiliaryLine', null)
                      }
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    isInvalid={touched.useAuxiliaryLine && errors.useAuxiliaryLine}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <InputGroup size='sm' hasValidation>
                    <Form.Control
                      type='number'
                      name='auxiliaryLine.startColumn'
                      value={nullishValueToValue(values.auxiliaryLine?.startColumn)}
                      onChange={(e) => {
                        if (values.auxiliaryLine) {
                          const auxiliaryLine = { ...values.auxiliaryLine }
                          auxiliaryLine.startColumn = nullishValueToInternalValue(e.target.value)
                          const newAuxiliaryLine = {
                            ...values.auxiliaryLine,
                            ...auxiliaryLine
                          }
                          console.log('newAuxiliaryLine', newAuxiliaryLine)
                          setFieldValue('auxiliaryLine', newAuxiliaryLine)
                        }
                      }}
                      disabled={!values.useAuxiliaryLine}
                      onBlur={handleBlur}
                      isInvalid={touched.auxiliaryLine?.startColumn && errors.auxiliaryLine?.startColumn}
                    />
                    <InputGroup.Text>段目</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.auxiliaryLine?.startColumn}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <InputGroup size='sm' hasValidation>
                    <Form.Control
                      type='number'
                      name='auxiliaryLine.startRow'
                      value={nullishValueToValue(values.auxiliaryLine?.startRow)}
                      onChange={(e) => {
                        if (values.auxiliaryLine) {
                          const auxiliaryLine = { ...values.auxiliaryLine }
                          auxiliaryLine.startRow = nullishValueToInternalValue(e.target.value)
                          const newAuxiliaryLine = {
                            ...values.auxiliaryLine,
                            ...auxiliaryLine
                          }
                          console.log('newAuxiliaryLine', newAuxiliaryLine)
                          setFieldValue('auxiliaryLine', newAuxiliaryLine)
                        }
                      }}
                      disabled={!values.useAuxiliaryLine}
                      onBlur={handleBlur}
                      isInvalid={touched.auxiliaryLine?.startRow && errors.auxiliaryLine?.startRow}
                    />
                    <InputGroup.Text>行目</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors.auxiliaryLine?.startRow}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group as={Col} sm={3}>
                  <InputGroup size='sm' hasValidation>
                    <Form.Control
                      type='number'
                      name='auxiliaryLine.length'
                      value={values?.auxiliaryLine?.length || ''}
                      onChange={handleChange}
                      disabled={!values.useAuxiliaryLine}
                      onBlur={handleBlur}
                      isInvalid={touched.auxiliaryLine?.length && errors.auxiliaryLine?.length}
                    />
                    <InputGroup.Text>段</InputGroup.Text>
                    <Form.Control.Feedback type='invalid'>
                      {errors?.auxiliaryLine?.length}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
              </Row>
            </Form.Group>
          </Row>
          <Row className='mb-2'>
            <Form.Group as={Col}>
              <Form.Label column>親記事</Form.Label>
              <Col>
                <Form.Control
                  as='select'
                  type='text'
                  name='parentId'
                  size='sm'
                  value={values.parentId}
                  onChange={(e) => {
                    const parentId = e.target.value
                    if (values.article) {
                      const relationType = parentId !== '' ? (values.article?.relationType || getDefaultRelationType(parentId)) : ''
                      const newArticle = {
                        ...values.article,
                        parentId,
                        relationType
                      }
                      setFieldValue('relationType', relationType)
                      setFieldValue('article', newArticle)
                    }
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  isInvalid={touched.parentId && errors.parentId}
                >
                  <option key='empty' value='' />
                  {parentTargetArticles?.map(a => (
                    <option key={`parentId-option-${a.id}`} value={a.id}>{a.optionName}</option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type='invalid'>
                  {errors.parentId}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label column>関連種別</Form.Label>
              <Col>
                {!nullish(values.parentId) &&
                  <>
                    <Form.Control
                      as='select'
                      type='text'
                      name='relationType'
                      size='sm'
                      value={values.relationType}
                      onChange={(e) => {
                        const relationType = e.target.value
                        if (values.article) {
                          const newArticle = {
                            ...values.article,
                            relationType
                          }
                          setFieldValue('article', newArticle)
                        }
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      disabled={nullish(values.parentId)}
                      isInvalid={touched.relationType && errors.relationType}
                    >
                      {Object.keys(enumValues.relationTypes).map(k => (
                        <option key={k} value={snakeCase(k)} disabled={isDisabledRelationTypeOption(k)}>{enumValues.relationTypes[k]}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type='invalid'>
                      {errors.priority}
                    </Form.Control.Feedback>
                  </>}
                {nullish(values.parentId) &&
                  <Form.Control readOnly size='sm' />}
              </Col>
            </Form.Group>
          </Row>
          {(values.articleType !== ArticleType.Area) &&
            <Row className='mb-2'>
              <Form.Group as={Col}>
                <Form.Label column>記事ファイル</Form.Label>
                <Col>
                  <Form.Control
                    id='file'
                    type='file'
                    name='file'
                    size='sm'
                    accept='.txt'
                    value={values.file}
                    onChange={handleFileChange}
                    onBlur={(e) => e.preventDefault()}
                    disabled={values.articleType === ArticleType.Area}
                    isInvalid={touched.file && errors.file}
                  />
                  <Form.Control.Feedback type='invalid' className='invalid-file'>
                    {errors.file}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
            </Row>}
          {isAreaArticleType(values.articleType) &&
            <>
              {values.articleType === ArticleType.Area &&
                <>
                  <Row className='mb-2'>
                    <Form.Group as={Col}>
                      <Form.Label>名称</Form.Label>
                      <Form.Control
                        name='name'
                        value={values.name || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.name && errors.name}
                      />
                      <Form.Control.Feedback type='invalid' className='invalid-name'>
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <AreaArticleFilenameInput
                    handleFileChange={handleFileChange}
                    {...formProps}
                  />
                </>}
              <AreaArticleAreaInput {...formProps} />
            </>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={onClickCancel}>
            キャンセル
          </Button>
          <Button type='submit' disabled={!values.article || !isValid}>{submitButtonText}</Button>
        </Modal.Footer>
      </Form>
    </>
  )
}
const areaXErrorMessage = '右から1以上にしてください'
const areaYErrorMessage = '1以上にしてください'
const startPosErrorMessage = '1以上にしてください'

ArticleUpsertForm.schema = Yup.object().shape({
  articleType: Yup.string().required(),
  columnSpaceExistence: Yup.boolean().required(),
  dividerLineMode: Yup.string().required(),
  priority: Yup.string().required(),
  filename: Yup.string().nullable(true),
  file: Yup.string(),
  deleteFile: Yup.boolean(),
  name: Yup.string().nullable(true),
  parentId: Yup.string().nullable(true),
  relationType: Yup.string().nullable(true),
  useAuxiliaryLine: Yup.boolean().required(),
  auxiliaryLine: Yup.object().nullable(true).shape({
    startColumn: Yup.number().required().min(0, startPosErrorMessage),
    startRow: Yup.number().required().min(0, startPosErrorMessage),
    length: Yup.number().required().min(1)
  }),
  rows: Yup.number().nullable(true).min(1),
  article: Yup.object().shape({
    areas: Yup.array(
      Yup.object().shape({
        x: Yup.number().min(0, areaXErrorMessage).when('$articleType', {
          is: (articleType) => isPositionRequired(articleType),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(true)
        }),
        y: Yup.number().min(0, areaYErrorMessage).when('$articleType', {
          is: (articleType) => isPositionRequired(articleType),
          then: (schema) => schema.required(),
          otherwise: (schema) => schema.nullable(true)
        }),
        width: Yup.number().min(1).nullable(true),
        height: Yup.number().min(1).nullable(true),
        fromLeft: Yup.boolean().nullable(true)
      })
    )
  })
})
